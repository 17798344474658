<template>
  <!-- 搜索结果页 -->
  <div class="search-box">
    <div class="search-content">
      <div class="search-list autoscroll-box" @scroll="pageScrollFn" ref="chatbox" v-infinite-scroll="load"
        infinite-scroll-distance="200" infinite-scroll-disabled="disabled">
        <search @search="searchClick" :path="'/video'" ref="child" />

        <div class="search-result">
          <div class="search-nophoto">
            <div class="search-title">
              搜索到<span>{{ total_count }}</span>个视频素材
            </div>
          </div>
        </div>
        <div v-if="dataList.length > 0">
          <div class="search-item" id="coryright-video-list">
            <video-card v-for="(item, index) in dataList" :key="index" @func="getMsgFormSon" :videodata="item">
            </video-card>
          </div>
        </div>
        <div class="loading-icon" v-if="loading">
          <span class="el-icon-loading"></span>
          <span class="images-request-tip">视频加载中…</span>
        </div>
        <div class="loading-icon" v-if="noMore && dataList.length > 0">
          <span class="images-request-tip">没有更多视频了</span>
        </div>
        <div v-show="dataList.length == 0 && loading" style="height: 300px"></div>
        <default-page class="default-page" v-if="defaultVisible && !loading" :status="defaultType"></default-page>

        <Footer v-cloak />
        <div class="back-top">
          <div class="to-top" @click="backToTop" v-show="pageScroll">
            <i class="iconfont icon-zhiding1"></i>
          </div>

        </div>
      </div>
    </div>
    <!-- 缺省页 -->
  </div>
</template>
<script>
import Search from "@/components/searchDesign";
import DefaultPage from "@/components/default";
import Footer from "@/components/foot.vue";
import VideoCard from "@/components/videoCard";
import { SearchApi } from "@/config/axios/api";
import $ from "jquery";
import "justifiedGallery/dist/css/justifiedGallery.css";
import justifiedGallery from "justifiedGallery";
export default {
  metaInfo: {
    title: "视频模板制作下载-视频设计素材免费下载-365版权图库",
    meta: [
      {
        name: "keywords",
        content: "视频素材,AE模板,PR模板,实拍视频",
      },
      {
        name: "description",
        content:
          "编辑器视频素材网站，为您提供视频素材免费下载，AE模板，PR模板，高清视频实时更新，海量音视频内容供您下载。",
      },
    ],
  },
  name: "VideoSearchPage",
  components: { Search, DefaultPage, Footer, VideoCard },
  data () {
    return {
      defaultVisible: false,
      defaultType: "", //缺省状态   nopic
      search: {
        page: 0,
        size: 35,
      },
      searchUrl: "",
      dataList: [],
      total_count: 0, //全部数据
      loading: false, //是否正在加载
      pageScroll: false,
      childShai: false,
      keyword: "",
    };
  },
  created () {
    this.search.search = this.$route.query.keyword || '';
    this.dataList = [];
    this.search.page = 0;
    this.load();
  },
  computed: {
    noMore () {
      return this.total_count <= this.dataList.length;
    },
    disabled () {
      return this.loading || this.noMore;
    },
  },
  mounted () {
    this.Statistics.defalutEvent({
      statsMark: "版权视频页曝光",
      projectPage: '61',
      projectArea: "137",
      eventArea: '137',
      eventType: '1',
      eventId: '1276',
      eventName: 'video_exposure',
    })
  },
  methods: {
    getMsgFormSon (data) {
      window.open(`/details?id=${data.editor_id}&video=true`, "_blank");
    },
    pageScrollFn () {
      if (this.$refs.chatbox.scrollTop > 135) {
        this.pageScroll = true;
      } else {
        this.pageScroll = false;
      }
      if (this.$refs.chatbox.scrollTop > 82) {
        this.$store.commit("setHeaderShow", true);
      } else {
        this.$store.commit("setHeaderShow", false);
      }
    },
    gotoDetail () {
      this.$router.push({
        name: "Detail",
      });
    },
    searchClick (val) {
      this.search.search = val;
      this.search.page = 0;
      this.dataList = [];
      this.total_count = 0;
      this.load();
    },
    gotoSearch () {
      this.search.search = this.keyword;
      this.search.page = 0;
      this.dataList = [];
      this.total_count = 0;
      this.load();
    },
    load () {
      this.search.page++;
      if (this.loading && this.noMore) return;
      this.loading = true;
      this.inpSearch();
    },
    //input搜索
    async inpSearch () {
      const res = await SearchApi.getVideo(this.search);
      this.loading = false;
      if (res.status == 1) {
        // 第一页的时候展示 关键词匹配的数据
        if (this.search.page == 1) {
          this.dataList = [];
        }
        if (res.data.data) {
          this.dataList = this.dataList.concat(res.data.data);
          this.defaultVisible = false;
          this.total_count = res.data.total;
        } else {
          this.total_count = 0;
        }
        if (this.total_count == 0) {
          this.defaultVisible = true;
          this.defaultType = "nopic";
        }
        this.addSomeImages();
      } else {
        this.defaultVisible = true;
        this.defaultType = "neterror";
      }
    },

    addSomeImages () {
      this.$nextTick(function () {
        $("#coryright-video-list").justifiedGallery({
          rowHeight: 161,
          margins: 15,
          border: 0,
          captions: true,
          waitThumbnailsLoad: false,
        });
        $("#coryright-video-list").justifiedGallery("norewind");
      });
    },

    backToTop () {
      var timer = 0;
      timer = setInterval(function () {
        document.querySelector(".autoscroll-box").scrollTop -= 30;
        if (document.querySelector(".autoscroll-box").scrollTop <= 0) {
          clearInterval(timer);
        }
      }, 10);
    },
  },
};
</script>
<style scoped lang="scss">
.default-page {
  width: 100%;
  margin: 20px 0;
}

#seach {
  .up-pic {
    top: 120px;
    right: 40px;
  }

  .hc-search {
    // margin-top: 56px;
    @include wh(100%, 130px);
    padding: 68px 40px 12px;
    box-sizing: border-box;
    background: #ffffff;
    //box-shadow: 0px 2px 8px 0px #F8F9FA;
    z-index: 10;

    .hc-search-inner {
      @include wh(100%, 50px);
      padding: 0 22px 0 23px;
      box-sizing: border-box;
      background: #f8f8f8;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;

      input {
        @include wh(94%, 100%);
        float: left;
        margin-left: 6px;
        background: none;
        font-size: 14px;

        &::placeholder {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }

      .img {
        margin-top: 14px;
        @include wh(22px, 22px);
        cursor: pointer;

        &.search {
          margin-top: 15px;
          @include wh(20px, 20px);
        }

        img {
          @include wh(100%, 100%);
        }

        &:hover {
          img {
            display: none;
          }

          img.hover {
            display: block;
          }
        }

        .hover {
          display: none;
        }
      }

      .line {
        width: 2px;
        height: 16px;
        margin: 17px 7px 0px 9px;
        background: #666666;
        border-radius: 1px;
      }
    }
  }
}

.search-box {
  background: #f8f9fa;
  height: calc(100vh - 60px);
  overflow: hidden;
  margin-top: 60px;
  position: relative;
}

.search-content {
  @include wh(100%, 100%);
  margin: 0 auto;
  overflow: hidden;

  .search-type {
    width: 100%;
    padding: 0 40px;
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 1;

    &>li {
      padding-right: 120px;
      min-width: 180px;
      float: left;

      p {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1f1f1f;
        line-height: 64px;
        cursor: pointer;

        span {
          margin-left: 10px;
          color: #1f1f1f;
          font-size: 14px;
        }
      }

      ul {
        padding: 5px 0 11px;

        li {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2a2a2a;
          line-height: 20px;
          margin-bottom: 14px;

          &.active {
            color: $activeColor;
          }

          cursor: pointer;
          position: relative;
          z-index: 1;
          float: left;
          clear: both;

          &:hover {
            color: $activeColor;

            &::before {
              content: "";
              display: block;
              @include wh(100%, 100%);
              padding: 2px 13px;
              position: absolute;
              left: 50%;
              top: 0;
              transform: translateX(-50%);
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto;
              background: #f1f0ff;
              z-index: -1;
              border-radius: 12px;
            }
          }
        }
      }
    }
  }

  .search-result {
    // padding: 0 40px 100px;
    margin: 0 auto;
    padding: 0 40px 0;
    box-sizing: border-box;

    /* .search-nophoto {
      padding-top: 64px;
    } */
    .search-type-result {
      margin-top: 19px;
      overflow: hidden;

      .item {
        position: relative;
        float: left;
        height: 26px;
        line-height: 26px;
        background: #f0f0f0;
        border-radius: 4px;
        padding: 0 32px 0 10px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1f1f1f;
        margin-right: 10px;

        span {
          position: absolute;
          right: 11px;
          top: 0;
          color: #666666;
          font-size: 12px;
          cursor: pointer;
        }
      }

      .close {
        margin-left: 11px;
        float: left;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        cursor: pointer;

        span {
          margin-right: 3px;
          font-size: 12px;
        }
      }
    }

    .search-title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      padding: 10px 10px 10px 10px;

      span {
        font-weight: bold;
        color: #1f1f1f;
      }
    }

    .search-photo-title {
      padding: 33px 0 20px;

      img,
      .el-image {
        @include wh(auto, 141px);
        margin-bottom: 33px;
      }

      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2d2d32;
        line-height: 20px;
      }
    }
  }

  .search-list {
    overflow: auto;
    height: 100%;
    padding: 0 25px 0;
    width: calc(100% + 50px);
    margin-left: -25px;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0;
    }

    .search-item {
      width: calc(100% - 80px);
      margin: 10px auto 80px;
      flex-wrap: wrap;
      @include flex(center, space-between);
    }
  }
}

.back-top {
  position: fixed;
  bottom: 110px;
  right: 38px;

  &>div {
    width: 58px;
    height: 58px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(201, 201, 201, 0.5);
    border-radius: 50%;
    @include flex(center, center);
    position: relative;
    color: #2b2b2b;
    cursor: pointer;

    &.contact {
      margin-top: 18px;

      &:hover {
        .info {
          display: block;
        }
      }
    }

    .info {
      display: none;
      position: absolute;
      right: 77px;
      top: 0;
      width: 208px;
      height: 58px;
      background: #ffffff;
      box-shadow: 0px 0px 12px 0px rgba(201, 201, 201, 0.5);
      padding: 0 24px 0 17px;
      box-sizing: border-box;
      text-align: center;
      border-radius: 4px;

      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 58px;

        span {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
        }
      }

      .avator-icon {
        position: absolute;
        top: 18px;
        right: -21px;
        width: 0;
        height: 0;
        border-bottom: 12px solid transparent;
        border-right: 12px solid transparent;
        border-left: 12px solid #fff;
        border-top: 12px solid transparent;
      }
    }
  }
}
</style>
